<template>
  <div>
    <MainLayout>
      <template v-slot:toolbar>
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <!--begin::Title-->
          <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Campanha - {{ campaign ? campaign.name : '' }}</h1>
          <!--end::Title-->
        </div>
        <!--begin::Actions-->
        <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
          <div class="m-0">
            <router-link
              :to="{ name: 'campaigns.index' }"
              class="btn btn-sm btn-primary"
            >
              Voltar
            </router-link>
          </div>
          <!--end::Filter menu-->
        </div>
        <!--end::Actions-->
        <!--end::Page title-->
      </template>

      <!-- CONTENT -->
      <template v-if="loader">
        <div class="py-5">
          <Loader size="big" />
        </div>
      </template>
      <template v-else>
        <ProgramaticCampaignShow
          :baseCampaign="campaign"
          v-if="campaign.type === 'PROGRAMATIC'"
        />
        <NativeCampaignShow
          :baseCampaign="campaign"
          v-if="campaign.type === 'NATIVE' || campaign.type === 'PUSH'"
        />
        <YoutubeCampaignShow
          :baseCampaign="campaign"
          v-if="campaign.type === 'YOUTUBE'"
        />
      </template> <!-- END CONTENT -->

    </MainLayout>
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'

import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'
import NativeCampaignShow from '@/modules/campaigns/views/native/NativeCampaignShow'
import ProgramaticCampaignShow from '@/modules/campaigns/views/programatic/ProgramaticCampaignShow'
import YoutubeCampaignShow from '@/modules/campaigns/views/youtube/YoutubeCampaignShow'

export default {
  components: {
    Loader,
    MainLayout,
    NativeCampaignShow,
    ProgramaticCampaignShow,
    YoutubeCampaignShow
  },
  metaInfo () {
    return {
      title: this.campaign ? this.campaign.name : ''
    }
  },
  data () {
    return {
      campaign: '',
      loader: false
    }
  },
  created () {
    this.getCampaign()
  },

  methods: {
    /**
     * Get campaign
     */
    async getCampaign () {
      this.loader = true
      try {
        const campaign = await CampaignsService.getCampaign(this.$route.params.id)
        this.campaign = campaign
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>
