<template>
  <div>
    <!-- CONTENT -->
    <!--begin::Navbar-->
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-0 pb-0">
        <!--begin::Navs-->
        <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
          <!--begin::Nav item-->
          <li class="nav-item mt-2">
            <a
              class="nav-link text-active-primary ms-0 me-10 py-5"
              :class="{
                active: currentTab === 'details'
              }"
              href="#"
              @click.prevent="changeTab('details')"
            >
              Detalhes
            </a>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item mt-2">
            <a
              class="nav-link text-active-primary ms-0 me-10 py-5"
              :class="{
                active: currentTab === 'pixels'
              }"
              href="#"
              @click.prevent="changeTab('pixels')"
            >
              Pixels
            </a>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item mt-2">
            <a
              class="nav-link text-active-primary ms-0 me-10 py-5"
              :class="{
                active: currentTab === 'wallets'
              }"
              href="#"
              @click.prevent="changeTab('wallets')"
            >
              Histórico de transações
            </a>
          </li>
          <!--end::Nav item-->
        </ul>
        <!--begin::Navs-->
      </div>
    </div>
    <!--end::Navbar-->

    <!-- DETALHES TAB -->
    <div v-show="currentTab === 'details'">
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Detalhes</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9">
          <template>
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">ID</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.id }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Nome</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.name }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Tipo</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.type | formatCampaignType }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Anunciante</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">
                  <router-link
                    :to="{ name: 'advertisers.show', params: { id: campaign.advertiser.id }}"
                  >
                    {{ campaign.advertiser.name }}
                  </router-link>
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Data de início</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.start_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Data de término</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.end_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Valor</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.amount/100 | formatMoney }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Valor gasto</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.spending/100 | formatMoney }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Valor disponível</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.available_amount/100 | formatMoney }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Meta de CPM</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.cpm_target_amount / 100 | formatMoney }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Meta de CPV</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.cpv_target_amount / 100 | formatMoney }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Browsers</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.browser.join(', ') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Status</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.status | formatStatus }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Plataforma</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.platform }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">ID na Plataforma</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.platform_id }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Impressões</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.impressions }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Visualizações</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.complete_views }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Conversões</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.conversions }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">VTR</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.vtr }}%</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">CPM</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.cpm/100 | formatMoney }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">CPA</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.cpa/100 | formatMoney }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Data de criação</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Data de última atualização</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ campaign.updated_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <Button
              class="btn-info mb-4"
              :loading="loader"
              @click="changeStatus"
              v-if="areLinesValid && areCreativesValid && campaign.status === 'PENDING'"
            >
              Definir como pausado e avisar que a campanha foi processada
            </Button>
            <!--end::Input group-->
            <!-- IMPERSONATE -->
            <div class="row mb-7">
              <h2 class="m-0 text-gray-900 flex-grow-1">Impersonate</h2>
              <div class="mt-5">
                <ImpersonateButton
                  :userId="campaign.user_impersonate_id"
                />
              </div>
            </div> <!-- END IMPERSONATE -->
          </template>
        </div>
        <!--end::Card body-->
      </div>

      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Criativos</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9">
          <CreativeListTable
            :creatives="line.creatives"
            :format="campaign.type"
          />
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View-->

      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Targeting</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9">
          <div class="mb-5">
            <h4 class="fw-bold m-0 mb-3">Audiências</h4>
            <template v-if="line.audiences.length > 0">
              <ul class="mb-0">
                <li
                  v-for="(audience, i) in line.audiences"
                  :key="i"
                >
                  {{ audience.name }}
                </li>
              </ul>
            </template>
            <template v-else>
              <p>Sem audiências selecionadas</p>
            </template>
          </div>
          <div class="mb-5">
            <h4 class="fw-bold m-0 mb-5">Localizações</h4>
            <h5 class="fw-bold m-0 mb-5">
              <span>Estados</span>
            </h5>
            <template v-if="line.states.length > 0">
              <ul class="mb-0">
                <li
                  v-for="(state, i) in line.states"
                  :key="i"
                >
                  {{ state.name }}
                </li>
              </ul>
            </template>
            <template v-else>
              <p>Sem estados selecionados</p>
            </template>
            <h5 class="fw-bold my-5">
              <span>Cidades</span>
            </h5>
            <template v-if="line.cities.length > 0">
              <ul class="mb-0">
                <li
                  v-for="(city, i) in line.cities"
                  :key="i"
                >
                  {{ city.name }} ({{ city.state_short_name }})
                </li>
              </ul>
            </template>
            <template v-else>
              <p>Sem cidades selecionadas</p>
            </template>
          </div>
          <div class="mb-5">
            <h4 class="fw-bold m-0 mb-5">Browsers</h4>
            <template v-if="line.browser.length > 0">
              <ul class="mb-0">
                <li
                  v-for="(browser, i) in line.browser"
                  :key="i"
                >
                  {{ browser }}
                </li>
              </ul>
            </template>
            <template v-else>
              <p>Sem browsers selecionados</p>
            </template>
          </div>
          <div class="mb-5">
            <h4 class="fw-bold m-0 mb-5">Audiências customizadas</h4>
            <template v-if="line.custom_audiences.length > 0">
              <ul class="mb-0">
                <li
                  v-for="(audience, i) in line.custom_audiences"
                  :key="i"
                >
                  {{ audience.name }}
                </li>
              </ul>
            </template>
            <template v-else>
              <p>Sem audiências customizadas selecionadas</p>
            </template>
          </div>
          <div class="mb-5">
            <h4 class="fw-bold m-0 mb-5">Blocklist</h4>
            <template v-if="line.blacklist.length > 0">
              <ul class="mb-0">
                <li
                  v-for="(item, i) in line.blacklist"
                  :key="i"
                >
                  {{ item.name }}
                </li>
              </ul>
            </template>
            <template v-else>
              <p>Sem blocklist selecionada</p>
            </template>
          </div>
          <div class="mb-5">
            <h4 class="fw-bold m-0 mb-5">Wishlist</h4>
            <template v-if="line.wishlist.length > 0">
              <ul class="mb-0">
                <li
                  v-for="(item, i) in line.wishlist"
                  :key="i"
                >
                  {{ item.name }}
                </li>
              </ul>
            </template>
            <template v-else>
              <p>Sem wishlist selecionada</p>
            </template>
          </div>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View-->
    <!--end::details View--> <!-- END DETAILS TAB -->
    </div>

    <!-- PIXELS TAB -->
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" v-show="currentTab === 'pixels'">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bold m-0">Detalhes</h3>
        </div>
        <!--end::Card title-->
        <!--begin::Action-->
        <!--end::Action-->
      </div>
      <!--begin::Card header-->
      <!--begin::Card body-->
      <div class="card-body p-9">
        <template>
          <div class="table-responsive">
            <!--begin::Table-->
            <table class="table align-middle table-row-dashed fs-6 gy-5">
              <!--begin::Table head-->
              <thead>
                <!--begin::Table row-->
                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                  <th class="min-w-100px">Nome do pixel</th>
                  <th class="min-w-70px">Nome da regra</th>
                  <th class="min-w-100px">Objetivo</th>
                  <th class="min-w-70px">Nome do evento</th>
                  <th class="min-w-70px">Status</th>
                </tr>
                <!--end::Table row-->
              </thead>
              <!--end::Table head-->
              <!--begin::Table body-->
              <tbody class="fw-semibold text-gray-600">
                <!--begin::Table row-->
                <tr v-for="(pixel, index) in campaign.pixels" :key="index">
                  <td>
                    <span>{{ pixel.pixel.name }}</span>
                  </td>
                  <td>
                    <span>{{ pixel.name }}</span>
                  </td>
                  <td>
                    <span>{{ pixel.objective }}</span>
                  </td>
                  <td>
                    <span>{{ pixel.event_name }}</span>
                  </td>
                  <td>
                    <span>{{ pixel.status | formatStatus }}</span>
                  </td>
                </tr>
                <!--end::Table row-->
              </tbody>
              <!--end::Table body-->
            </table>
          </div>
          <!--end::Table-->
        </template>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::details View--> <!-- END PIXELS TAB -->

    <!-- WALLETS TAB -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" v-show="currentTab === 'wallets'">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Histórico de transações</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9" v-if="campaign">
          <WalletListTable
            :campaignId="campaign.id"
          />
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View--> <!-- END WALLETS TAB -->

    <!-- END CONTENT -->
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'

import Button from '@/components/common/Button/Button'
import CreativeListTable from '@/components/creatives/CreativeListTable'
import ImpersonateButton from '@/components/users/ImpersonateButton'
import WalletListTable from '@/components/wallets/WalletListTable'

export default {
  props: ['baseCampaign'],
  components: {
    Button,
    CreativeListTable,
    ImpersonateButton,
    WalletListTable
  },
  data () {
    return {
      campaign: '',
      currentLineTab: 0,
      currentPackageTab: 0,
      currentTab: 'details',
      loader: false
    }
  },
  created () {
    this.campaign = this.baseCampaign
  },
  computed: {
    areLinesValid () {
      return this.campaign.packages.every(pkg =>
        pkg.lines.every(line => line.platform_id)
      )
    },
    areCreativesValid () {
      return this.campaign.creatives.every(creative => creative.google_id)
    },
    /**
     * Creatives
     */
    creatives () {
      let array = []
      if (this.campaign.creatives.length > 0) array = [...array, ...this.campaign.creatives]
      return array
    },
    /**
     * Get lines
    */
    line () {
      return this.campaign.packages[0].lines[0]
    },
    /**
     * Current tab title
     */
    tabTitle () {
      switch (this.currentTab) {
        case 'packages':
          return 'Grupos de Estratégias'
        default:
          return 'Detalhes'
      }
    }
  },
  methods: {
    /**
     * change status campaign
     */
    async changeStatus () {
      this.loader = true
      try {
        await CampaignsService.changeCampaignStatus(this.campaign.id)
        location.reload()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Change line tab
     */
    changeLineTab (tab) {
      this.currentLineTab = tab
    },
    /**
     * Change package tab and reset line tab
     */
    changePackageTab (tab) {
      this.currentPackageTab = tab
      this.currentLineTab = 0
    },
    /**
     * Change current tab
     */
    changeTab (tab) {
      this.currentTab = tab
    }
  }
}
</script>
